<template>
  <div class="VIPclass">
    <!-- 搜索 -->
    <kind-choose-view :show-school="true" :show-kc="false" :show-zy="true" :type="null" :entrants-type="3" @isOk="isOk" />
    <!-- 套餐列表 -->
    <div class="contentWrap">
      <div v-for="(item, index) in exclusiveClassList" :key="index" class="contentItem" @click="goDetails(item)">
        <img :src="item.img" class="imgItem" />
        <div class="item_content">
          <div class="titleItem twoEllipsis">
            <img v-if="item.productLine == 3" style="
                width: 63.22px;
                margin-bottom: 2px;
                vertical-align: middle;
                display: inline-block;
              " src="@/assets/img/partner/vipzs.png" alt="" />{{ item.tiitle }}
          </div>
          <div class="priceItem">
            <div class="symbolOrprice">
              <span class="symbol">{{ '￥' }}</span>
              <span class="price">{{ item.price }}</span>
            </div>
            <div class="fire">
              <span class="courseNum">{{
                item.kechengNum ? item.kechengNum + '门课程' : '暂无课程'
              }}</span>
              <!-- <img class="fire-icon" src="@/assets/img/homeSeventh/fire.png" />
              <div>{{ item.clicks }}人气值</div> -->
            </div>
          </div>
          <div class="line"></div>
          <div class="tips">
            <span class="tips_item dis_flex_start">
              <img src="@/assets/img/partner/right.png" alt="" />
              <div>专属服务</div>
            </span>
            <span class="tips_item dis_flex_start">
              <img src="@/assets/img/partner/right.png" alt="" />
              <div>专属考场</div>
            </span>
            <span class="tips_item dis_flex_start">
              <img src="@/assets/img/partner/right.png" alt="" />
              <div>专属教材</div>
            </span>
            <span class="tips_item dis_flex_start">
              <img src="@/assets/img/partner/right.png" alt="" />
              <div>定期班会</div>
            </span>
            <span class="tips_item dis_flex_start">
              <img src="@/assets/img/partner/right.png" alt="" />
              <div>督学跟踪</div>
            </span>
            <span class="tips_item dis_flex_start">
              <img src="@/assets/img/partner/right.png" alt="" />
              <div>8小时极速答疑</div>
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="contentWrap_item" v-for="item in empty" :key="item">
        <div class="item_item">
          <el-image class="item_img" :src="require('@/assets/img/partner/empty.png')"></el-image>
        </div>
        <div class="item_name">更多课程，敬请期待</div>
      </div> -->
      <el-empty style="margin: 0 auto;" description="暂无数据" v-if="exclusiveClassList.length == 0"></el-empty>
    </div>
    <!-- 分页 -->
    <el-pagination class="pagination partner-pagination" prev-text="上一页" next-text="下一页" :current-page="pageNum"
      :page-size="pageSize" layout="prev, pager, next" :total="total" @current-change="handleCurrentChange" />
  </div>
</template>

<script>
import Vue from 'vue'
import KindChooseView from '../../components/kindChooseViewkvipSeventh.vue'
import { selectPageListToIndex, selectNewPageListToIndex } from '@/api/home'

export default {
  components: {
    KindChooseView
  },
  data() {
    return {
      exclusiveClassList: [],
      pageSize: 12,
      pageNum: 1,
      total: 0,
      search: {},
      loading: null
    }
  },
  async beforeDestroy() {
    this.loading.close()
  },
  created() {
    this.selectNewPageListToIndex()
  },
  computed: {
    empty() {
      if (Math.trunc(this.total / 12) + 1 === this.pageNum) {
        return 4 - this.total % 12
      } else {
        return 0
      }

      // if()

    }
  },
  methods: {
    /* 筛选 */
    isOk(val) {
      this.pageNum = 1
      this.search = val
      console.log(val)
      this.selectNewPageListToIndex()
    },
    /* VIP专属班列表 */
    selectNewPageListToIndex() {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      })
      selectNewPageListToIndex(
        this.pageSize,
        this.pageNum,
        this.search.school,
        this.search.kind,
        this.search.zyId,
        undefined,
        3 /* productLine 3专属线 */,
        this.search.stageName,
        this.search.areaId == 1 ? undefined : this.search.areaId
      )
        .then((res) => {
          this.exclusiveClassList = res.rows
          this.total = res.total
          this.loading.close()
        })
        .catch(() => {
          this.loading.close()
        })
    },
    /* 点击进入专属班详情 */
    goDetails(item) {
      /* this.$router.push({
        path: "/VIP/VIPClassxq",
        query: {
          id: item.id,
        },
      }); */
      const routeUrl = this.$router.resolve({
        path: '/VIP/VIPClassxq',
        query: {
          id: item.id
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    /* 每页条数 */
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.selectNewPageListToIndex()
    },
    /* 当前页码 */
    handleCurrentChange(val) {
      this.pageNum = val
      this.selectNewPageListToIndex()
    }
  }
}
</script>

<style lang="less" scoped>
.VIPclass {
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  overflow: hidden;

  .contentWrap {
    margin: 0px calc(50% - 600px);
    width: calc(1200px + 24px);
    height: auto;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;

    .contentItem {
      width: 280px;
      height: 316px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      cursor: pointer;
      margin: 0 27px 21px 0;

      &:nth-child(4n) {
        margin-right: 0;
      }

      .imgItem {
        width: 100%;
        height: 157px;
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
        position: relative;

        .img {
          width: 100%;
          height: 161px;
          border-radius: 8px 8px 0 0;
        }

        .imgTips {
          width: 80px;
          height: 26px;
          background: linear-gradient(134deg, #5e6c77 0%, #222733 100%);
          border-radius: 8px 0 8px 0;
          text-align: center;
          line-height: 26px;
          color: #ffbe5d;
          font-size: 14px;
          font-family: Microsoft YaHei-Bold;
          font-weight: bold;
          font-style: italic;
          position: absolute;
          top: 0;
          left: 0;
          /* -webkit-background-clip: text; */
          /* -webkit-text-fill-color: transparent; */
        }

        .oneToOne {
          border-radius: 8px 0 8px 0;
          width: 77px;
          height: 24px;
          background: url('~@/assets/img/Home/oneToOne/one-to-one-tips.png');
          position: absolute;
          top: 0;
          left: 0;
          color: #ffffff;
          line-height: 24px;
          padding-left: 9px;
        }
      }

      .item_content {
        padding: 14px 16px;

        .titleItem {
          width: 247px;
          height: 46px;
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #333333;
          line-height: 23px;
        }

        .tips {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-top: 5px;

          .tips_item {
            // width: 40px;
            height: 14px;
            align-items: center;
            margin-right: 25px;
            margin-top: 6px;
            font-size: 10px !important;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #a3a9ba;

            &:nth-child(3n) {
              margin-right: 0;
            }

            img {
              width: 9px;
              height: 6px;
              margin-right: 5px;
            }
          }

          .fuwu {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #b8681e;
            line-height: 12px;
          }

          .el-divider {
            margin: auto 8px;
            height: 8px;
            background: #999999;
          }

          .courseNum {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #777A82;
            line-height: 12px;
          }
        }

        .priceItem {
          margin-top: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .symbolOrprice {
            height: 25px;
            font-size: 18px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #fb2d25;
            line-height: 25px;

            .symbol {
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-size: 12px;
              font-weight: 400;
              line-height: 12px;
            }
          }

          .fire {
            display: flex;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 12px;

            .fire-icon {
              width: 12px;
              height: 12px;
              flex-shrink: 0;
              margin-right: 4px;
            }
          }
        }
      }
    }

    .contentWrap_item {
      width: 280px;
      height: 316px;
      background: #FFFFFF;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 27px;

      .item_item {
        width: 63px;
        height: 54px;
        margin-top: -20px;

        .item_img {
          width: 100%;
          height: 100%;
        }
      }

      .item_name {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #E4E9ED;
        margin-top: 7px;
      }
    }

    .contentWrap_item:nth-child(4n) {
      margin-right: 0;
    }
  }

  .pagination {
    margin: auto;
  }
}

.line {

  height: 0px;
  opacity: 1;
  border-bottom: 1px dotted #EEEEEE;
  margin-top: 10px;
}
</style>
